<template>
  <div id="app">
    <default-theme>
      <router-view :key="$route.fullPath" />
    </default-theme>
  </div>
</template>
<script>
import DefaultTheme from "@/themes/Default";

export default {
  name: "App",
  components: {
    DefaultTheme,
  },
  computed: {
    key() {
      return this.$route.fullPath;
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/css/style.css";
</style>
