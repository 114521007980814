<template>
    <div class="header-component">
        <div class="navbar_top bg2-color py-4 d-none d-lg-block">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-lg-5 col-xxl-5">
                        <div class="navbar_top__left d-flex align-items-center gap-2 gap-xl-6">
                            <a href="mailto:contact@piton.network"
                                class="navbar_top__email roboto p7-color d-flex align-items-center gap-3">
                                <i class="ti ti-mail-opened-filled fs-four"></i>
                                contact@piton.network</a>
                        </div>
                    </div>
                    <div class="col-lg-7 col-xxl-6">
                        <div class="navbar_top__right d-flex align-items-center justify-content-end gap-2 gap-xl-6">
                            <div class="navbar_top__call d-flex align-items-center gap-3">

                            </div>
                            <div class="navbar_top__social d-flex align-items-center gap-2 gap-xl-3">
                                <span class="p7-color fw-bolder">Follow Us:</span>
                                <div class="navbar_top__social-icon d-flex align-items-center  gap-1 gap-xl-2">
                                    <a href="#" class="br3 py-2 px-3 rounded-item d-center">
                                        <i class="ti ti-brand-facebook fs-four p7-color "></i>
                                    </a>
                                    <a href="#" class="br3 py-2 px-3 rounded-item d-center">
                                        <i class="ti ti-brand-instagram fs-four p7-color "></i>
                                    </a>
                                    <a href="https://twitter.com/pitonnetwork" class="br3 py-2 px-3 rounded-item d-center">
                                        <i class="ti ti-brand-twitter fs-four p7-color "></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <header class="header-section header-menu w-100 pt-1 pt-lg-0 pb-1 pb-lg-0">
            <div class="navbar_mainhead header-fixed w-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-12">
                            <nav class="navbar navbar-expand-lg position-relative py-lg-6 workready">
                                <router-link :to="{ name: 'home' }"
                                class="navbar-brand d-flex align-items-center gap-2">
                                <img src="../assets/images/piton.png" class="logo" alt="logo">
                                </router-link>
                                <div class="collapse navbar-collapse justify-content-between" id="navbar-content">
                                    <ul
                                        class="navbar-nav d-flex align-items-lg-center gap-5 gap-lg-1 gap-xl-4 gap-xxl-7 py-2 py-lg-0 ms-2 ms-xl-10 ms-xxl-20 ps-0 ps-xxl-10 align-self-center">
                                        <li class="dropdown show-dropdown">
                                            <router-link :to="{ name: 'home' }"
                                            class="fs-ten"
                                            active-class="active">
                                                Home
                                            </router-link>
                                        </li>
                                        <li class="dropdown show-dropdown">
                                            <router-link :to="{ name: 'staking-detail' }"
                                            class="fs-ten"
                                            active-class="active">
                                                Staking
                                            </router-link>
                                        </li>
                                        <li class="dropdown show-dropdown">
                                            <router-link :to="{ name: 'about-us' }"
                                            class="fs-ten"
                                            active-class="active">
                                                About us
                                            </router-link>
                                        </li>
                                        <li class="dropdown show-dropdown">
                                            <router-link :to="{ name: 'roadmap' }"
                                            class="fs-ten"
                                            active-class="active">
                                                Roadmap
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>

                                <div class="right-area custom-pos position-relative d-flex gap-0 gap-lg-2 align-items-center">
                                    <div class="header-section__modalstyle">
                                        <a type="button"
                                            href="https://app.ston.fi/swap?chartVisible=false&ft=TON&tt=PITON"
                                            target="_blank"
                                            class="cmn-btn px-3 px-sm-5 px-md-6
                                            py-2 py-sm-3 d-flex align-items-center gap-1">
                                            Buy PITON
                                        </a>
                                    </div>
                                </div>

                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                    aria-label="Navbar Toggler" data-bs-target="#navbar-content" aria-expanded="true"
                                    id="nav-icon3">
                                    <span></span><span></span><span></span><span></span>
                                </button>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: 'HeaderSection',
  data() {
    return {
      showNavbar: false,
      sidebarCollapse: {}
    };
  },
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    ...mapActions({
    }),
    toogleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    collapseSidebar(key) {
      this.sidebarCollapse = {
        ...this.sidebarCollapse,
        [key]: !this.sidebarCollapse[key]
      };
    }
  },
}
</script>

<style lang="scss">
    .header-component {
        .header-section {
            .logo {
                height: 38px;
            }
            .navbar-toggler {
                height: 30px !important;
            }
        }
    }
</style>
