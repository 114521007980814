<template>
  <div>
    <!-- Scroll To Top Start-->
    <button class="scrollToTop d-md-flex d-center" aria-label="scroll Bar Button">
        <i class="ti ti-chevron-up fs-four p7-color"></i>
    </button>
    <!-- Scroll To Top End -->

    <HeaderSection/>
    <slot />
    <FooterSection/>
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue'
import FooterSection from '@/components/FooterSection.vue'
// import _ from "lodash";

export default {
  name: "DefaultTheme",
  components: {
    HeaderSection,
    FooterSection
  },
};
</script>

<style scoped>

</style>
