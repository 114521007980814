import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/Home/Index.vue'),
      meta: { title: "Home", activeMenu: "home" }
  },
  {
    path: '/staking-detail',
    name: 'staking-detail',
    component: () =>
      import(/* webpackChunkName: "staking" */ '../views/Staking/Detail.vue'),
      meta: { title: "Staking detail", activeMenu: "staking" }
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () =>
      import(/* webpackChunkName: "about-us" */ '../views/General/AboutUs.vue'),
      meta: { title: "About us", activeMenu: "aboutus" }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () =>
      import(/* webpackChunkName: "privacy" */ '../views/General/Privacy.vue'),
      meta: { title: "Privacy", activeMenu: "privacy" }
  },
  {
    path: '/roadmap',
    name: 'roadmap',
    component: () =>
      import(/* webpackChunkName: "roadmap" */ '../views/General/Roadmap.vue'),
      meta: { title: "Roadmap", activeMenu: "roadmap" }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error404',
    component: () =>
      import(/* webpackChunkName: "errors" */ "../views/Error/PageNotFound.vue"),
      meta: { title: "404 page", activeMenu: "*" }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})
if (history.scrollRestoration) {
  history.scrollRestoration = "manual";
}

router.beforeEach((to, from, next) => {
  next();
  
  // if (to.meta.requiresAuth && !auth.isLoggedIn()) {
  //   // this route requires auth, check if logged in
  //   // if not, redirect to login page.
  //   return {
  //     path: '/login',
  //     // save the location we were at to come back later
  //     query: { redirect: to.fullPath },
  //   }
  // }
})

export default router
